<template>
  <div class="warpbg"></div>
</template>

<script>
import { Base64 } from 'js-base64'
import { parseQueryString } from '@/utils'

export default {
  created() {
    this.initView()
    const queryString = Base64.decode(this.$route.query.p)
    const queryParams = parseQueryString(queryString)
    this.numberId = queryParams.number
    console.log(queryString)
    console.log(this.numberId)
  },
  methods: {
    initView () {
      let scale = 1.0 / window.devicePixelRatio;
      let text = `<meta name="viewport" content="width=device-width, initial-scale=${scale}, maximum-scale=${scale}, minimum-scale=${scale}, user-scalable=no">`;
      document.write(text);
      document.documentElement.style.fontSize = window.innerWidth / 7.5 + "px";
    },
  }

}
</script>

<style>
html,
body {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
}
.warpbg {
  height: 11.20rem;
  width: 100%;
  background: url('../../assets/activity/collect/pre_bg.jpg') top center no-repeat;
  background-size: contain;
  position: relative;
}
</style>